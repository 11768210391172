import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createAsyncThunkWithHandler } from "../api";
import authService from "./authService";
const user = JSON.parse(localStorage.getItem("lyl-user"));
const initialState = {
  user: user ? user : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const register = createAsyncThunkWithHandler(
  "auth/register",
  async (user, _) => {
    return await authService.register(user);
  }
);

export const login = createAsyncThunkWithHandler(
  "auth/login",
  async (user, _) => {
    return await authService.login(user);
  }
);

export const forgotPassword = createAsyncThunkWithHandler(
  "auth/forgotPassword",
  async (user, _) => {
    return await authService.forgotPassword(user);
  }
);

export const update = createAsyncThunkWithHandler(
  "auth/update",
  async (user, thunkAPI) => {
    const userId = thunkAPI.getState().auth.user._id;
    const token = thunkAPI.getState().auth.user.token;
    return await authService.update(user, userId, token);
  }
);

export const updatePassword = createAsyncThunkWithHandler(
  "auth/updatePassword",
  async (user, thunkAPI) => {
    const userId = thunkAPI.getState().auth.user._id;
    const token = thunkAPI.getState().auth.user.token;
    return await authService.updatePassword(user, userId, token);
  }
);

export const verify = createAsyncThunkWithHandler(
  "auth/verify",
  async (data, _) => {
    return await authService.verify(data);
  }
);

export const deleteUser = createAsyncThunkWithHandler(
  "auth/deleteUser",
  async (userId, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    return await authService.deleteUser(userId, token);
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await authService.logout();
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(update.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Congratulations your verification is now in review";
        state.user = action.payload;
      })
      .addCase(update.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(verify.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(verify.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updatePassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = "Password updated successfully";
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
