import React, { useState } from "react";
import { Modal, Container, Typography, Button, Grid, Box } from "@mui/material";
import { Card, Timeline, Tag, message, Watermark } from "antd";
import {
  DownloadOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EnvironmentOutlined,
  CalendarOutlined,
  UserOutlined,
  PhoneOutlined,
  BankOutlined,
  FileTextOutlined,
  DollarOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";

const Section = ({ title, children, icon, status = null }) => (
  <Card
    className="mb-6 shadow-md"
    headStyle={{
      backgroundColor: "#f8fafc",
      borderBottom: "1px solid #e2e8f0",
      padding: "16px 24px",
    }}
    title={
      <Box className="flex items-center gap-4">
        <span className="flex-shrink-0">{icon}</span>
        <Typography
          variant="h3"
          component="div"
          className="flex-grow text-sm"
          sx={{ fontSize: "15px" }}>
          {title}
        </Typography>
        {status !== null && (
          <span className="flex-shrink-0">
            <StatusBadge status={status} />
          </span>
        )}
      </Box>
    }
    bodyStyle={{ padding: "24px" }}>
    {children}
  </Card>
);

const InfoField = ({ label, value, icon }) => (
  <Box className="mb-4 flex items-start gap-3">
    <Box className="text-blue-600 mt-1">{icon}</Box>
    <Box>
      <Typography variant="subtitle2" gutterBottom className="text-gray-600">
        {label}
      </Typography>
      <Typography variant="body1" className="font-medium">
        {value || "—"}
      </Typography>
    </Box>
  </Box>
);

const StatusBadge = ({ status }) => (
  <Tag
    icon={status ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
    color={status ? "success" : "error"}
    className="px-3 py-1 flex items-center gap-2 text-sm">
    {status ? "Suitable for Purchase" : "Not Recommended"}
  </Tag>
);

const CompanyHeader = ({ documentId }) => (
  <Box className="flex justify-between items-center mb-8 pb-4 border-b-2 border-blue-600">
    <Box>
      <Typography variant="h4" gutterBottom>
        <span className="text-green-600 font-bold text-2xl">Lock.</span>
        <span className="font-bold text-neutral-900 text-2xl">your</span>
        <span className="text-blue-800 font-bold text-2xl">.land</span>
      </Typography>
      <Box className="flex gap-4 text-gray-600 italic">
        <Typography>Due Diligence</Typography>
        <Typography>•</Typography>
        <Typography>Due Process</Typography>
        <Typography>•</Typography>
        <Typography>Dependable</Typography>
      </Box>
    </Box>
    <Box className="bg-white p-4 rounded-lg shadow-md">
      <QRCode value={documentId} size={100} level="H" />
    </Box>
  </Box>
);

const ReportModal = ({
  openReportModal,
  setOpenReportModal,
  documentReport,
}) => {
  const [loader, setLoader] = useState(false);
  const handleClose = () => setOpenReportModal(false);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(amount);
  };

  const downloadPDF = async () => {
    message.loading({ content: "Generating PDF...", key: "pdfGeneration" });
    setLoader("true");

    try {
      const element = document.querySelector(".report-content");
      const canvas = await html2canvas(element, {
        scale: 2, // Increased scale for better quality
        useCORS: "true",
        logging: false,
        windowWidth: element.scrollWidth,
        windowHeight: element.scrollHeight,
        onclone: (clonedDoc) => {
          // Handle the cloned element for better rendering
          const clonedElement = clonedDoc.querySelector(".report-content");
          if (clonedElement) {
            // Ensure all content is visible and properly sized
            clonedElement.style.width = `${element.scrollWidth}px`;
            clonedElement.style.minHeight = `${element.scrollHeight}px`;
            clonedElement.style.position = "relative";
            clonedElement.style.background = "#ffffff";
            clonedElement.style.padding = "20px";

            // Ensure all sections are visible
            // const sections = clonedElement.querySelectorAll(".ant-card");
            // sections.forEach((section) => {
            //   section.style.pageBreakInside = "avoid";
            //   section.style.marginBottom = "20px";
            // });
          }
        },
      });

      const imgWidth = 210; // A4 width in mm
      const pixelRatio = canvas.width / canvas.height;
      const imgHeight = imgWidth / pixelRatio;

      const pdf = new JsPDF({
        orientation: imgHeight > imgWidth ? "portrait" : "landscape",
        unit: "mm",
        format: "a4",
      });

      // Calculate the number of pages needed
      const pageHeight = 297; // A4 height in mm
      const pagesNeeded = Math.ceil(imgHeight / pageHeight);

      // Add pages and split content across them
      for (let i = 0; i < pagesNeeded; i++) {
        if (i > 0) {
          pdf.addPage();
        }

        // Calculate the portion of the image to use for this page
        const sourceY = (canvas.height / pagesNeeded) * i;
        const sourceHeight = canvas.height / pagesNeeded;

        pdf.addImage(
          canvas.toDataURL("image/jpeg", 1.0),
          "JPEG",
          0,
          -sourceY * (imgWidth / canvas.width), // Adjust y position for current page
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );
      }

      // Save the PDF
      pdf.save(
        `Property-Search-Report-${documentReport.clientName}-${
          new Date().toISOString().split("T")[0]
        }.pdf`
      );

      message.success({
        content: "PDF downloaded successfully!",
        key: "pdfGeneration",
        duration: 2,
      });
    } catch (error) {
      console.error("Error generating PDF:", error);
      message.error({
        content: "Failed to generate PDF. Please try again.",
        key: "pdfGeneration",
        duration: 3,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal
      open={openReportModal}
      onClose={handleClose}
      aria-labelledby="report-modal-title"
      aria-describedby="report-modal-description"
      className="overflow-y-auto"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Container maxWidth="lg" className="my-8">
        <Button
          variant="contained"
          onClick={downloadPDF}
          startIcon={<DownloadOutlined />}
          disabled={loader}
          size="large">
          {loader ? "Generating PDF..." : "Download PDF"}
        </Button>
        <Watermark content={["Verified By", "LockyourLand Legal Team"]}>
          <div className="report-content bg-white p-8 rounded-lg max-h-[90vh] overflow-y-auto">
            <CompanyHeader documentId={documentReport.docId} />

            <Box className="flex justify-between items-center mb-6 flex-wrap gap-4">
              <Typography variant="h2" sx={{ fontSize: "15px" }}>
                Property Search Report
              </Typography>
            </Box>

            <Section
              title="Search Information"
              icon={<FileTextOutlined className="text-blue-600" />}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <InfoField
                    icon={<EnvironmentOutlined />}
                    label="Search Location"
                    value={documentReport.searchConductedAt}
                  />
                  <InfoField
                    icon={<CalendarOutlined />}
                    label="Date of Search"
                    value={formatDate(documentReport.dateOfSearch)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InfoField
                    icon={<UserOutlined />}
                    label="Conducted By"
                    value={documentReport.searchConductedBy}
                  />
                  <InfoField
                    icon={<TeamOutlined />}
                    label="Department Head"
                    value={documentReport.departmentHead}
                  />
                </Grid>
              </Grid>
            </Section>

            <Section
              title="Property Details"
              icon={<BankOutlined className="text-blue-600" />}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <InfoField
                    icon={<EnvironmentOutlined />}
                    label="Location"
                    value={documentReport.location}
                  />
                  <InfoField
                    icon={<FileTextOutlined />}
                    label="Size/Measurement"
                    value={`${documentReport.sizeMeasurement} ${documentReport.measurementScale}`}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InfoField
                    icon={<DollarOutlined />}
                    label="Purchase Price"
                    value={formatCurrency(documentReport.purchasePrice)}
                  />
                  <InfoField
                    icon={<UserOutlined />}
                    label="Client Name"
                    value={documentReport.clientName}
                  />
                </Grid>
              </Grid>
            </Section>

            <Section
              title="Search Findings"
              icon={<FileTextOutlined className="text-blue-600" />}
              status={documentReport.isSuitableForPurchase == "true"}>
              <Timeline
                items={[
                  {
                    color: "blue",
                    children: (
                      <Box>
                        <Typography
                          variant="subtitle1"
                          className="font-semibold">
                          Ownership Details
                        </Typography>
                        <Box className="ml-4 mt-2">
                          <InfoField
                            label="Registered Owner(s)"
                            value={documentReport.registeredOwners}
                            icon={<UserOutlined />}
                          />
                          <InfoField
                            label="Title Documents"
                            value={documentReport.titleDocuments}
                            icon={<FileTextOutlined />}
                          />
                        </Box>
                      </Box>
                    ),
                  },
                  {
                    color:
                      documentReport.hasEncumbrances == "true"
                        ? "red"
                        : "green",
                    children: (
                      <Box>
                        <Typography
                          variant="subtitle1"
                          className="font-semibold">
                          Encumbrances
                        </Typography>
                        <Typography className="mt-2">
                          {documentReport.hasEncumbrances == "true"
                            ? documentReport.encumbrancesDetails
                            : "No encumbrances found"}
                        </Typography>
                      </Box>
                    ),
                  },
                  {
                    color:
                      documentReport.hasDisputes == "true" ? "red" : "green",
                    children: (
                      <Box>
                        <Typography
                          variant="subtitle1"
                          className="font-semibold">
                          Disputes/Litigation
                        </Typography>
                        <Typography className="mt-2">
                          {documentReport.hasDisputes == "true"
                            ? documentReport.disputesDetails
                            : "No disputes or litigation found"}
                        </Typography>
                      </Box>
                    ),
                  },
                ]}
              />
            </Section>

            <Section
              title="Certification"
              icon={<FileTextOutlined className="text-blue-600" />}>
              <Box className="bg-gray-50 p-6 rounded-lg border border-blue-200">
                <Typography paragraph>
                  I, <strong>{documentReport.lawyerName}</strong>, hereby
                  certify that this search was conducted at the{" "}
                  {documentReport.searchConductedAt} Land Registry on{" "}
                  {formatDate(documentReport.dateOfSearch)}. I confirm that the
                  information provided in this report is accurate and complete
                  to the best of my knowledge.
                </Typography>
                <Box className="flex justify-between items-center mt-6">
                  <Typography variant="subtitle1">
                    <strong>Report Generated:</strong>{" "}
                    {formatDate(documentReport.createdAt)}
                  </Typography>
                  <Box
                    className="border-b-2 border-blue-600 flex justify-center w-[25%] h-16 bg-cover bg-center"
                    style={{
                      backgroundImage: `url(${documentReport.signature})`,
                    }}></Box>
                </Box>
              </Box>
            </Section>
          </div>
        </Watermark>
      </Container>
    </Modal>
  );
};

export default ReportModal;
