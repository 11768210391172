import axios from "axios";
import { API_URL } from "../api";

const getTitlePerfectionPrice = async (state) => {
  const response = await axios.get(`${API_URL}/titlePerfection/${state}`);
  return response.data;
};

const requestTitlePerfectionPrice = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/titlePerfection/request/user/${data.UserId}/doc/${data.DocId}/`,
    data,
    config
  );
  return response.data;
};

const paymentTitlePerfectionPrice = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/titlePerfection/payment/${data.reference}/user/${data.UserId}/doc/${data.DocId}/`,
    data,
    config
  );
  return response.data;
};

const titlePerfectionService = {
  getTitlePerfectionPrice,
  requestTitlePerfectionPrice,
  paymentTitlePerfectionPrice,
};
export default titlePerfectionService;
