import React, { useMemo } from "react";
import {
  Box,
  Typography,
  Paper,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

const TitlePerfectionDetails = ({
  titlePerfectionData,
  propertyValue = 0,
  state,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const calculations = useMemo(() => {
    if (!titlePerfectionData || !propertyValue) return null;

    const directAssessment =
      (propertyValue * titlePerfectionData.DirectAssessment) / 100;
    const stampsDuty = (propertyValue * titlePerfectionData.StampsDuty) / 100;
    const adminCharge = (propertyValue * titlePerfectionData.AdminCharge) / 100;
    const developmentLevy = titlePerfectionData.DevelopmentLevy;

    const total = directAssessment + stampsDuty + adminCharge + developmentLevy;

    return {
      directAssessment,
      stampsDuty,
      adminCharge,
      developmentLevy,
      total,
    };
  }, [titlePerfectionData, propertyValue]);

  return (
    <Box sx={{ mt: 2, mb: 4 }}>
      <Paper elevation={0} sx={{ p: 3, bgcolor: "#f8fafc" }}>
        <Typography
          variant="subtitle1"
          sx={{
            color: "text.secondary",
            fontWeight: 500,
          }}>
          {titlePerfectionData?.State === state ? (
            `${state} State Pricing`
          ) : (
            <>
              Default pricing based on{" "}
              <Box
                component="span"
                sx={{ color: "primary.main", fontWeight: 600 }}>
                {titlePerfectionData?.State} State
              </Box>
            </>
          )}
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 1, display: "flex", alignItems: "center", gap: 1 }}>
            <InfoOutlined fontSize="small" />
            All percentage calculations are based on property value
          </Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box>
            <Typography variant="subtitle2" color="text.secondary">
              Direct Assessment ({titlePerfectionData?.DirectAssessment}%)
            </Typography>
            <Typography variant="h6">
              ₦{calculations?.directAssessment?.toLocaleString()}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Required for Governor's Consent to perfect title transfer
            </Typography>
          </Box>

          <Divider />

          <Box>
            <Typography variant="subtitle2" color="text.secondary">
              Stamp Duty ({titlePerfectionData?.StampsDuty}%)
            </Typography>
            <Typography variant="h6">
              ₦{calculations?.stampsDuty?.toLocaleString()}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Tax on property transfer documentation
            </Typography>
          </Box>

          <Divider />

          <Box>
            <Typography variant="subtitle2" color="text.secondary">
              Admin Charge ({titlePerfectionData?.AdminCharge}%)
            </Typography>
            <Typography variant="h6">
              ₦{calculations?.adminCharge?.toLocaleString()}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Processing fees for documentation and administrative tasks
            </Typography>
          </Box>

          <Divider />

          <Box>
            <Typography variant="subtitle2" color="text.secondary">
              Development Levy (Fixed)
            </Typography>
            <Typography variant="h6">
              ₦{calculations?.developmentLevy?.toLocaleString()}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              State government infrastructure development charge
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 3,
            p: 2,
            bgcolor: "primary.main",
            borderRadius: 1,
            color: "white",
          }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Total Cost
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            ₦{calculations?.total?.toLocaleString()}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default TitlePerfectionDetails;
