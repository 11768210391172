import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { escrowPayment, reset } from "../features/payments/payment_slice";
import { paymentForTitlePerfection } from "../features/titlePerfection/titlePerfectionSlice";

const useHandleTitlePerfectionPayment = ({ docId }) => {
  const [handleOpenPaymentModal, setOpenPaymentModal] = useState(false);
  const { isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.titlePerfection
  );
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleTitlePerfectionPayment = (reference) => {
    console.log(reference);
    dispatch(
      paymentForTitlePerfection({ reference, DocId: docId, UserId: user?._id })
    );
  };

  useEffect(() => {
    if (isError) {
      setOpenPaymentModal(false);
      Swal.fire({
        icon: "error",
        title: "Ooops",
        text: message,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(function () {
        dispatch(reset());
        setOpenPaymentModal(false);
      });
    }

    if (isSuccess) {
      setOpenPaymentModal(false);
      Swal.fire({
        icon: "success",
        title: "",
        text: message,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(function () {
        window.location.reload(false);
        dispatch(reset());
        setOpenPaymentModal(false);
      });
    }
  }, [isError, isSuccess, message, dispatch]);

  return {
    handleOpenPaymentModal,
    handleTitlePerfectionPayment,
    isLoading,
    setOpenPaymentModal,
  };
};

export default useHandleTitlePerfectionPayment;
