import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import verifiedImg from "../../assets/verified.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReportModal from "../addons/ReportModal";

const DisplaySearchAndSurveyDocumentForProperties = ({ property }) => {
  const [openReportModal, setOpenReportModal] = useState(false);
  return (
    <>
      <Box>
        <Box className="mt-3">
          <Accordion
            sx={{
              background: "#fafafa",
              padding: "0% 1%",
            }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Button
                variant="contained"
                color="success"
                disableElevation
                size="small"
                sx={{
                  fontSize: "11px",
                  width: "100%",
                }}
                startIcon={
                  <img
                    src={verifiedImg}
                    alt="verified img"
                    className="w-7 h-7"
                  />
                }>
                Survey Completed, Click to View
              </Button>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2}>
                <a
                  target="_blank"
                  href={`${property?.document?.SurveyProof?.[0]}`}>
                  <Box className="rounded-md border py-2 px-3 text-[#127539] cursor-pointer bg-[#daf2e4]">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}>
                      Survey Document
                    </Typography>
                  </Box>
                </a>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>

      <Box>
        <Box className="mt-3">
          <Accordion
            sx={{
              background: "#fafafa",
              padding: "0% 1%",
            }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Button
                variant="contained"
                color="success"
                disableElevation
                size="small"
                sx={{
                  fontSize: "11px",
                  width: "100%",
                }}
                startIcon={
                  <img
                    src={verifiedImg}
                    alt="verified img"
                    className="w-7 h-7"
                  />
                }>
                Validation Successful, Click to View
              </Button>
            </AccordionSummary>
            <AccordionDetails>
              <ReportModal
                openReportModal={openReportModal}
                setOpenReportModal={setOpenReportModal}
                documentReport={property?.documentReport}
              />
              <Stack spacing={2}>
                <Box
                  className="rounded-md border py-2 px-3 text-[#127539] cursor-pointer bg-[#daf2e4]"
                  onClick={() => setOpenReportModal(true)}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}>
                    Summary Report
                  </Typography>
                </Box>

                <a
                  target="_blank"
                  href={`${property?.document?.DocumentProof?.[0]?.LandRegistory}`}>
                  <Box className="rounded-md border py-2 px-3 text-[#127539] cursor-pointer bg-[#daf2e4]">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}>
                      Land Registory Doc
                    </Typography>
                  </Box>
                </a>
                <a
                  target="_blank"
                  href={`${property?.document?.DocumentProof?.[1]?.ProbateRegistory}`}>
                  <Box className="rounded-md border py-2 px-3 text-[#127539] cursor-pointer bg-[#daf2e4]">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}>
                      Probate Registory Doc
                    </Typography>
                  </Box>
                </a>
                <a
                  target="_blank"
                  href={`${property?.document?.DocumentProof?.[2]?.CacSearch}`}>
                  <Box className="rounded-md border py-2 px-3 text-[#127539] cursor-pointer bg-[#daf2e4]">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}>
                      CAC Search Doc
                    </Typography>
                  </Box>
                </a>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </>
  );
};

export default DisplaySearchAndSurveyDocumentForProperties;
