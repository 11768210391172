import React from "react";
import { Button, Grid, Paper, Box } from "@mui/material";
import PaymentModal from "../addons/PaymentModal";
import { Typography } from "antd";
import useHandleTitlePerfectionPayment from "../../hooks/useHandleTitlePerfectionPayment";

const InitiateTitlePerfectionPayment = ({ property }) => {
  const {
    handleOpenPaymentModal,
    handleTitlePerfectionPayment,
    isLoading,
    setOpenPaymentModal,
  } = useHandleTitlePerfectionPayment({ docId: property?.document?._id });

  return (
    <>
      <Grid container spacing={2} direction="column" alignItems="stretch">
        <Grid item>
          <Typography className="text-[13px] font-bold">
            Title Perfection Payment
          </Typography>
        </Grid>
        <Grid item>
          <Typography className="text-[12px]">
            Title perfection pricing document has been confirmed:
          </Typography>
        </Grid>
        <Grid item>
          <Typography className="text-[12px]">
            Please make the final payment of{" "}
            <Box component="span" fontWeight="bold">
              {(property?.document?.TitlePerfectionConfirmedPrice).toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: "NGN",
                }
              )}
            </Box>{" "}
            for the process to be completed
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            disableElevation
            disabled={isLoading}
            fullWidth
            sx={{
              bgcolor: "#000",
              color: "#fff",
              textTransform: "none",
              fontWeight: "bold",
              "&:hover": {
                bgcolor: "#333",
              },
            }}
            onClick={() => setOpenPaymentModal(!handleOpenPaymentModal)}
            size="small">
            {isLoading ? "Please wait..." : "Continue"}
          </Button>
        </Grid>
      </Grid>
      <PaymentModal
        handleOpenPaymentModal={handleOpenPaymentModal}
        setOpenPaymentModal={setOpenPaymentModal}
        handleTitlePerfectionPayment={handleTitlePerfectionPayment}
        amount={property?.document?.TitlePerfectionConfirmedPrice}
        titlePerfection={true}
      />
    </>
  );
};

export default InitiateTitlePerfectionPayment;
