import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import WarningIcon from "@mui/icons-material/Warning";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, logout } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function DeleteAccountForm() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, isSuccess, isError, message, user } = useSelector(
    (state) => state.auth
  );

  const handleDelete = () => {
    // Add your delete account logic here
    dispatch(deleteUser(user?._id));
  };

  React.useEffect(() => {
    if (isSuccess) {
      navigate("/");
      dispatch(logout());
      handleClose();
    }

    if (isError) {
      toast.error(message);
    }
  }, [isLoading, isSuccess, isError, message]);

  return (
    <div>
      <Button
        variant="contained"
        sx={{
          bgcolor: "#dc2626",
          "&:hover": {
            bgcolor: "#991b1b",
          },
          textTransform: "none",
          fontWeight: "bold",
          marginTop: "3%",
        }}
        size="medium"
        onClick={handleOpen}>
        Delete Account
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <WarningIcon sx={{ color: "#dc2626", mr: 2, fontSize: 30 }} />
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ fontWeight: "bold" }}>
              Delete Account
            </Typography>
          </Box>

          <Typography id="modal-modal-description" sx={{ mb: 3 }}>
            Are you sure you want to delete your account? This action cannot be
            undone and all your data will be permanently removed.
          </Typography>

          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{
                textTransform: "none",
                borderColor: "#d1d5db",
                color: "text.primary",
                "&:hover": {
                  borderColor: "#9ca3af",
                },
              }}>
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              variant="contained"
              disabled={isLoading}
              sx={{
                bgcolor: "#dc2626",
                "&:hover": {
                  bgcolor: "#991b1b",
                },
                textTransform: "none",
              }}>
              {isLoading ? "Please wait..." : "Delete Account"}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
