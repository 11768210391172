import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunkWithHandler } from "../api";
import paymentService from "./titlePerfectionService";

const initialState = {
  titlePerfections: [],
  singleTitlePerfection: {},
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

export const getTitlePerfectionPrice = createAsyncThunkWithHandler(
  "titlePerfection/getTitlePerfectionPrice",
  async (state, _) => {
    const data = await paymentService.getTitlePerfectionPrice(state);
    return data;
  }
);

export const requestTitlePerfectionPrice = createAsyncThunkWithHandler(
  "titlePerfection/requestTitlePerfectionPrice",
  async (titlePerfectionData, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const data = await paymentService.requestTitlePerfectionPrice(
      token,
      titlePerfectionData
    );
    return data;
  }
);

export const paymentForTitlePerfection = createAsyncThunkWithHandler(
  "titlePerfection/paymentForTitlePerfection",
  async (titlePerfectionData, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const data = await paymentService.paymentTitlePerfectionPrice(
      token,
      titlePerfectionData
    );
    return data;
  }
);

const titlePerfectionSlice = createSlice({
  name: "titlePerfection",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestTitlePerfectionPrice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(requestTitlePerfectionPrice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = "Request sent successfully";
      })
      .addCase(requestTitlePerfectionPrice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(paymentForTitlePerfection.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(paymentForTitlePerfection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = "Payment made successfully";
      })
      .addCase(paymentForTitlePerfection.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(getTitlePerfectionPrice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTitlePerfectionPrice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.singleTitlePerfection = action.payload;
      })
      .addCase(getTitlePerfectionPrice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      });
  },
});

export const { reset } = titlePerfectionSlice.actions;
export default titlePerfectionSlice.reducer;
