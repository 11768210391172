import {
  Box,
  Button,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Loader } from "lucide-react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTitlePerfectionPrice,
  requestTitlePerfectionPrice,
  reset,
} from "../../features/titlePerfection/titlePerfectionSlice";
import TitlePerfectionDetails from "./TitlePerfectionDetails";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const TitlePerfectionModal = ({
  handleCloseTitlePerfectionModal,
  openTitlePerfectionModal,
  State,
  propertyValue,
  DocId,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.documents);
  const {
    isLoading: titlePerfectionLoading,
    singleTitlePerfection,
    isSuccess,
    isError,
    message,
  } = useSelector((state) => state.titlePerfection);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTitlePerfectionPrice(State));
  }, []);

  useEffect(() => {
    if (isSuccess && message === "Request sent successfully") {
      toast.success(message);
      dispatch(reset());
      navigate(0);
    }

    if (isError) {
      toast.error(message);
      dispatch(reset());
    }
  }, [isSuccess, isError, message]);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "95%" : "600px", // Increased width for better layout
    maxWidth: "700px",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: isMobile ? 2 : 4,
    maxHeight: "90vh",
    overflow: "auto", // Added scrolling for long content
  };

  const handleSubmit = () => {
    dispatch(requestTitlePerfectionPrice({ DocId, UserId: user?._id }));
  };

  return (
    <Modal
      open={openTitlePerfectionModal}
      onClose={handleCloseTitlePerfectionModal}
      aria-labelledby="payment-modal-title"
      aria-describedby="payment-modal-description">
      {titlePerfectionLoading ? (
        <Box className="flex items-center justify-center h-screen">
          <Loader />
        </Box>
      ) : (
        <Box sx={modalStyle}>
          <Typography
            id="payment-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontWeight: "bold",
              fontSize: isMobile ? "16px" : "20px",
              mb: 2,
              color: "primary.main",
            }}>
            Request For Title Perfection
          </Typography>

          <TitlePerfectionDetails
            titlePerfectionData={singleTitlePerfection}
            propertyValue={propertyValue}
            state={State}
          />

          <Button
            variant="contained"
            disabled={isLoading}
            onClick={handleSubmit}
            sx={{
              bgcolor: "#1e40af",
              textTransform: "none",
              fontWeight: "bold",
              marginTop: "3%",
            }}
            className="bg-blue-700 w-full"
            size="medium"
            type="submit">
            {isLoading ? "Please wait..." : "Proceed"}
          </Button>
        </Box>
      )}
    </Modal>
  );
};

export default TitlePerfectionModal;
