import React, { useState } from "react";
import { Box, Typography, Avatar, Chip, Button } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import verified from "../../assets/verified.png";
import UpdateProfileForm from "../Login/UpdateProfileForm";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import TourOutlinedIcon from "@mui/icons-material/TourOutlined";
import { url } from "../../utils/Index";
import UpdatePasswordForm from "../Login/UpdatePasswordForm";
import {
  maskAddress,
  maskEmail,
  maskPhoneNumber,
} from "../../helpers/maskedFunctions";
import DeleteAccountForm from "../Login/DeleteAccountForm";
const UserDetails = () => {
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      if (user?.VerifiedUser === "Not Verified") {
        const handleClick = () => {
          setOpen(true);
        };
        handleClick({
          vertical: "top",
          horizontal: "left",
        });
      }
    }, 3000);
  }, []);
  const { user } = useSelector((state) => state.auth);

  const vertical = "top";
  const horizontal = "left";

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const details = [
    {
      Title: maskEmail(user?.Email),
      Icon: <MailOutlineIcon style={{ fontSize: "17px" }} />,
    },
    {
      Title: maskPhoneNumber(user?.Phone?.toString()),
      Icon: <PhoneInTalkIcon style={{ fontSize: "17px" }} />,
    },
    {
      Title: maskAddress(user?.Address),
      Icon: <HomeOutlinedIcon style={{ fontSize: "17px" }} />,
    },
    {
      Title: user?.State,
      Icon: <AddLocationAltOutlinedIcon style={{ fontSize: "17px" }} />,
    },
    {
      Title: user?.Country,
      Icon: <TourOutlinedIcon style={{ fontSize: "17px" }} />,
    },
  ];

  var imgURL = user?.Profile;
  return (
    <>
      <Box component="div" className=" p-3 flex   flex-col">
        {loading ? (
          <Skeleton
            animation="wave"
            variant="circular"
            width={70}
            height={70}
          />
        ) : (
          <Avatar
            alt="Remy Sharp"
            src={imgURL}
            sx={{ height: "70px", width: "70px" }}
          />
        )}

        {loading ? (
          <>
            <Skeleton
              variant="rectangular"
              width={210}
              height={20}
              style={{ marginBottom: 6, marginTop: 10 }}
            />
            <Skeleton
              variant="rectangular"
              width={210}
              height={20}
              style={{ marginBottom: 6 }}
            />
            <Skeleton
              variant="rectangular"
              width={210}
              height={20}
              style={{ marginBottom: 6 }}
            />
            <Skeleton
              variant="rectangular"
              width={210}
              height={20}
              style={{ marginBottom: 6 }}
            />
          </>
        ) : (
          <>
            <Box className="flex items-center ">
              <Typography
                variant="h6"
                sx={{
                  fontSize: "17px",
                  fontWeight: "bold",
                  marginTop: "2%",
                  textTransform: "uppercase",
                }}>
                {user?.Firstname || user?.Firstname}{" "}
                {user?.Lastname || user?.Lastname}
              </Typography>
              {user?.VerifiedUser === "completed" && (
                <img src={verified} width="20px" />
              )}
            </Box>

            {user?.VerifiedUser != "Not Verified" ? (
              <>
                {details.map((detail, index) => {
                  return (
                    <Box
                      className="flex space-x-1 items-center mb-3"
                      key={index}>
                      {detail.Icon}
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "13px",
                          fontWeight: "bold",
                          marginTop: "0%",
                          textTransform: "",
                          opacity: "70%",
                          textTransform: "capitalize",
                        }}>
                        {detail.Title}
                      </Typography>
                    </Box>
                  );
                })}
              </>
            ) : (
              <Box className="flex space-x-1 items-center mb-3">
                <MailOutlineIcon style={{ fontSize: "17px" }} />
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    marginTop: "0%",
                    textTransform: "",
                    opacity: "70%",
                    textTransform: "capitalize",
                  }}>
                  {user?.Email || user?.Email}
                </Typography>
              </Box>
            )}

            {user?.VerifiedUser === "Not Verified" ? (
              <Tooltip title="Update Profile to get verified">
                <Chip
                  label="Not Verified"
                  color="error"
                  variant="outlined"
                  className="w-5/12"
                  size="small"
                />
              </Tooltip>
            ) : user?.VerifiedUser === "pending" ? (
              <Chip
                label="Verification in Review"
                color="primary"
                variant="outlined"
                className="w-8/12"
                size="small"
              />
            ) : (
              <Chip
                label="Verified User"
                color="success"
                variant="outlined"
                className="w-8/12"
                size="small"
              />
            )}
            <Box className="">
              {user?.VerifiedUser !== "completed" && <UpdateProfileForm />}
              <UpdatePasswordForm />
              <DeleteAccountForm />
            </Box>
          </>
        )}
      </Box>
      <div>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Update your profile to get Verified"
          action={action}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        />
      </div>
    </>
  );
};

export default UserDetails;
